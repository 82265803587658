import { graphql } from 'gatsby'
import React from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css" 
import Img from "gatsby-image"

const RResidentielCuisinePage = ({ data }) => { 
 
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    dots:true,
    draggable:true, 
  };

  const g = data.gallery.edges.map(({ node }) => node.childImageSharp)
  const g1 = data.g1.edges.map(({ node }) => node.childImageSharp)
  const g2 = data.g2.edges.map(({ node }) => node.childImageSharp)
  const g3 = data.g3.edges.map(({ node }) => node.childImageSharp)
  const g4 = data.g4.edges.map(({ node }) => node.childImageSharp)
 
  return(
      <Layout>
        <SEO title="Agencement" />
        <div class="w-full relative">
            <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
                <h1 class="text-center text-2xl mt-5 font-bold">Nos Réalisations Cuisine</h1>
                <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
             </div>
            <div className="slider-wrapper">
              <Slider  {...settingsMain}>
              { data.sld.edges.map(({ node, index }) =>
                  <div className="slick-slide" key={index}>
                      <Img className="slick-slide-image" fluid={node.childImageSharp.full} />
                  </div>
                )}
              </Slider>
            </div> 
        </div>  
      <div class="grid grid-cols-1 mt-8 md:mx-40 md:my-5 mx-3"> 
          <p class="mb-3 mt-10">
          Vous rêvez d’une cuisine qui s’adapte à tous vos besoins ? Qui soit chaleureuse, originale, pratique ?
          Chez Corsaf Design vous trouverez la cuisine qui vous ressemble.
         </p>
         <ul class="list-disc pl-10">
            <li>Plan de travail sur mesure</li>
            <li>Eviers</li>
            <li>plinthe</li>
        </ul>
      </div> 
      <div class="w-full gl">
          <Gallery images={g1} />
          <Gallery images={g2} />
          <Gallery images={g3} />
          <Gallery images={g4} />
          <Gallery images={g} />
      </div>
      </Layout> 
 )
}
export const query = graphql`
  query RresidentielCuisineQuery {
    gallery:allFile (filter:{relativeDirectory: {eq: "img/realisations/residentiel-cuisine/r-g"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    g1:allFile (filter:{relativeDirectory: {eq: "img/realisations/residentiel-cuisine/1"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    g2:allFile (filter:{relativeDirectory: {eq: "img/realisations/residentiel-cuisine/2"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    g3:allFile (filter:{relativeDirectory: {eq: "img/realisations/residentiel-cuisine/3"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    g4:allFile (filter:{relativeDirectory: {eq: "img/realisations/residentiel-cuisine/4"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    sld:allFile (filter:{relativeDirectory: {eq: "img/realisations/residentiel-cuisine/r-s"}}){
      edges {
        node {
          childImageSharp {
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
export default RResidentielCuisinePage